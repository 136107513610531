import React, { createContext, useEffect, useRef, useState} from 'react'
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

export const UserProfileContext = createContext()

const UserProfileContextProvider = (props) =>
{
    const [userProfile, setUserProfile] = useState({operatorUID:"", name:"", valid:false});

    const shouldExecute = useRef(true);
    
    useEffect(() =>
    {
      if(shouldExecute.current)
      {
        shouldExecute.current = false;

        getUserContext().then(values => setUserProfile(values));

      }
    },[]);

    const getUserContext = async () =>
    { 
      const docSnap = await getDoc(doc(db, "users", "zt1IMo9COkmifhsEGzf7SArVSHC9"));
      let ed = {valid:false};
      
      if (docSnap.exists())
      {
        console.log("Document data:", docSnap.data());
        ed = {valid: true, ...docSnap.data()};
      } 
      else
      {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }

      return ed;
    }

    return (
         <UserProfileContext.Provider 
            value={{
                userProfile
             }}>
               {props.children}
         </UserProfileContext.Provider>
    )
}
export default UserProfileContextProvider