import React, { useEffect, useState, }from "react";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

export function PlaygroundSelectionDialog(props)
{
  const { onClose, open } = props;
  const [playgrounds, setPlaygrounds] = useState([]);

  useEffect(() =>
  {
    if(open === true)
    {
      getPlaygrounds().then(values => setPlaygrounds(values));
    }
  }, [open] );

  const handleClose = () =>
  {
    //onClose(selectedValue);
  };

  const handleListItemClick = (value) =>
  {
    onClose(value);
  };

  const getPlaygrounds = async () =>
  {
    const pg = [];
    
    const q = query(collection(db, "/operators/gheS3SffzfnjTP3al04p/playgrounds/"),
                    where("address.city", "==", "Obermichelbach"));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) =>
    {
      pg.push({id: doc.id, ...doc.data()});
    });

    return (pg);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Spielplatz auswählen</DialogTitle>
      <List sx={{ pt: 0 }}>
        {playgrounds && playgrounds.map((playground) => {
          return(
          <>
            <ListItem disableGutters>
              <ListItemButton onClick={() => handleListItemClick(playground)} key={playground.name}>
                <ListItemText primary={playground.name} />
              </ListItemButton>
            </ListItem>
          </>
          )})}
      </List>
    </Dialog>
  );
};