import React, {useContext} from "react";

import { collection, getDocs, addDoc } from "firebase/firestore";
import { db } from "../../firebase";

import { PlaygroundSelectionDialog} from "../playground/PlaygroundSelectionDialog";
import OrderDTO, {orderConverter} from "./OrderDTO";
import {UserProfileContext} from  "../../contexts/UserProfileContext";

export function OrderCreationDialog(props)
{
  const { onClose } = props;
  const [openPlaygroundSelectionDialog, setPlaygroundSelectionDialog] = React.useState(false);
  const { userProfile } = useContext(UserProfileContext);

  const handlePlaygroundSelectionDialogClose = (playground) =>
  {
    console.log("Selected playground:", playground);

    setPlaygroundSelectionDialog(false);
    
    createOrder(playground).then((order) =>
    {
      onClose(order);
    });
  };

  async function createOrder(playground)
  {
    const order = new OrderDTO({playground: playground.name, playground_id: playground.id});
    order.avatar = playground.avatar;
    order.user   = userProfile.name;

    //We need to await here, because the id of the newly created order is needed
    let docref = await addDoc(collection(db, '/operators/gheS3SffzfnjTP3al04p/orders').withConverter(orderConverter), order);
    order.id = docref.id;

    getDocs(collection(db, `/operators/gheS3SffzfnjTP3al04p/playgrounds/${playground.id}/equipment`)).then((equipment) =>
    {
      equipment.forEach((eq) => {
        order.addEquipment(eq.data());
      });

      order.equipment.forEach((equipment) => {
        addDoc(collection(docref, 'equipment'), equipment);
      });
    });

    return order;
  }

  React.useEffect(() =>
  {
    setPlaygroundSelectionDialog(props.open);
  }, [props.open]);

  return (
    <>
    <PlaygroundSelectionDialog
        open={openPlaygroundSelectionDialog}
        onClose={handlePlaygroundSelectionDialogClose}
    />
    </>
  );
}