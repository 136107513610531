import React, {useRef} from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { collection, getDocs, doc, updateDoc, increment } from "firebase/firestore";
import { db } from "../../firebase";

import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getDownloadURLForImage}  from "../../services/helper";

import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import DefectDialog from "../defect/DefectDialog";
import OrderHeader from "./OrderHeader";
import { InspectionFinishedDialog } from "../inspection/InspectionFinishedDialog";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%'
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function EquipmentList(props)
{
  const [notInspectedEquipment, setNotInspectedEquipment] = React.useState([]);
  const [inspectedEquipment   , setInspectedEquipment]    = React.useState([]);
  const [selectedItem, setSelectedItem]                   = React.useState();

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const shouldExecute = useRef(true);

  const [openDefectDialog, setOpenDefectDialog] = React.useState(false);

  const handleEquipmentNoDefect = (selectedItem) =>
  {
    updateEquipmentAsNoDefectInDB(selectedItem.id);

    //only UI changes
    setToInspected(selectedItem);
  };

  const handleEquipmentDefect = (selectedItem) =>
  {
    setSelectedItem(selectedItem);

    setOpenDefectDialog(true);
  };

  const handleDefectDialogCancel = () =>
  {
    setOpenDefectDialog(false);
  }

  const handleDefectReasonEntered = (defectProps) =>
  {
    updateEquipmentAsDefectInDB(selectedItem.id, defectProps);

    updateOrderDefectCount();

    //only UI changes
    setToInspected(selectedItem);

    setOpenDefectDialog(false);
  }

  const updateEquipmentAsNoDefectInDB = (equipmentId) =>
  {
    //no images of defects have been taken, directly write to DB.
    updateDoc(doc(db, `/operators/gheS3SffzfnjTP3al04p/orders/${ props.orderInfo.order.id }/equipment/${equipmentId}`),
              {
                inspectiontime: new Date().toLocaleString(),
                inspected: true
              });
  }

  const updateOrderDefectCount = () =>
  {
    // Update number of defects in the order
    updateDoc(doc(db, `/operators/gheS3SffzfnjTP3al04p/orders/${ props.orderInfo.order.id }`),
              { number_of_defects: increment(1) });
  }

  const updateEquipmentAsDefectInDB = (equipmentId, defectDetails) =>
  {
    const equipmentRef   = doc(db, `/operators/gheS3SffzfnjTP3al04p/orders/${ props.orderInfo.order.id }/equipment/${equipmentId}`);
    let   numberOfErrors = defectDetails.images.length;

    let defectData =
    {
      defecttext: defectDetails.reason,
      defectImages: [],
      inspectiontime: new Date().toLocaleString(),
      inspected: true
    }

    if(numberOfErrors > 0)
    {
      defectDetails.images.forEach(function(image)
      {
        let fileEnding = image.name.split('.').pop();
        let uniqueFileName = Math.floor(Math.random() * Date.now())+"."+fileEnding;

        uploadBytes(ref(getStorage(), `orders/${ props.orderInfo.order.id }/${uniqueFileName}`), image).then((result) =>
        {
          console.log('Uploaded defect image to storage!', result);

          getDownloadURLForImage(result.metadata.fullPath).then( (url) =>
          {
            defectData.defectImages.push(url);
          
            if(--numberOfErrors === 0)
            {
              //this will be done only if all images have been successfully uploaded.
              updateDoc(equipmentRef, defectData);
            }
          });
        });
      });
    }
    else
    {
      //no images of defects have been taken, directly write to DB.
      updateDoc(equipmentRef, defectData);
    }

    console.log("Defect data saved", defectData);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateInspectedState = (id, value) =>
  {
    const equipmentRef = doc(db, `/operators/gheS3SffzfnjTP3al04p/orders/${ props.orderInfo.order.id }/equipment/${id}`);

    updateDoc(equipmentRef, {
      inspected: value
    });
  }

  const handleSetToUninspected = (selectedItem) =>
  {
    //remove from inspected list
    setInspectedEquipment(inspectedEquipment.filter(item => item.id !== selectedItem.id));

    //add to not inspected list
    setNotInspectedEquipment(notInspectedEquipment => [...notInspectedEquipment, selectedItem]);

    updateInspectedState(selectedItem.id, false);
  };

  const setToInspected = (selectedItem) =>
  {
    //remove from not inspected list
    setNotInspectedEquipment(notInspectedEquipment.filter(item => item.id !== selectedItem.id));

    //add to inspected list
    setInspectedEquipment(inspectedEquipment => [...inspectedEquipment, selectedItem]);

    //check if it is the last equipment in the list
    if(notInspectedEquipment.length === 1)
    {
      props.onAllItemsInspected();
    }
  }
  
  React.useEffect(() =>
  {
    const getOrderDetails = async () =>
    { 
      const querySnapshot = await getDocs(collection(db, `/operators/gheS3SffzfnjTP3al04p/orders/${ props.orderInfo.order.id }/equipment`));

      querySnapshot.forEach((doc) =>
      {
        console.log('Equipment:', doc.data());

        if(doc.get("inspected") === true)
        {
          setInspectedEquipment(inspectedEquipment => inspectedEquipment.concat({id: doc.id, ...doc.data()}));  
        }
        else
        {
          setNotInspectedEquipment(notInspectedEquipment => notInspectedEquipment.concat({id: doc.id, ...doc.data()}));  
        }
      });
    }

    if(shouldExecute.current)
    {
      shouldExecute.current = false;

      getOrderDetails();
    }
  }, []);

  if (!notInspectedEquipment) {
    return null;
  }

  return (
    <>
    {openDefectDialog && <DefectDialog onDefectReasonEntered={(images) => handleDefectReasonEntered(images)} onCancel={() => handleDefectDialogCancel()}/>}
       
    <Box sx={{ bgcolor: 'background.paper', flexgrow: 1}}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={`Offen(${notInspectedEquipment.length})`} {...a11yProps(0)} />
          <Tab label={`Erledigt(${inspectedEquipment.length})`} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
          {notInspectedEquipment.length ? ( 
            <>
            <Typography component="h2" variant="h6" color="primary">
              {notInspectedEquipment[0].name}
            </Typography>

            <img src={`${notInspectedEquipment[0].image}`} style={{width: "90%", left: 0, right: 0 }} resizemode="contain" />

            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={() => {handleEquipmentNoDefect(notInspectedEquipment[0])}} startIcon={<SentimentSatisfiedAltIcon />} style={{backgroundColor: 'lightgreen'}}>
                OK
              </Button>
              <Button variant="contained" onClick={() => {handleEquipmentDefect(notInspectedEquipment[0])}} endIcon={<SentimentVeryDissatisfiedIcon />} style={{backgroundColor: 'Tomato'}}>
                Mangel
              </Button>
            </Stack>
            </>
          ) : ''}
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {inspectedEquipment.map((item, index) => ( 
            <><Grid item xs={6} >
              <Item style={{
              backgroundImage: `url(${item.image})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}></Item>
            </Grid>
            <Grid item xs={6} key={item.id}>
              <Item>{item.name}
              <Stack spacing={2} direction="row">
                <Button variant="contained" onClick={() => {handleSetToUninspected(item)}}>Ja</Button>
              </Stack>
              </Item>
            </Grid></>
          ))}
        </Grid>
        </Box>
      </TabPanel>
    </Box>
    </>
  );
}

export function Order()
{
  const [openInspectionFinishedDialog, setOpenInspectionFinishedDialog] = React.useState(false);
  const [allItemsInspected, setAllItemsInspected] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleAllItemsInspected = () =>
  {
    setAllItemsInspected(true);
    setOpenInspectionFinishedDialog(true);
  };

  const handleOnCloseInspection = () =>
  {
    if(allItemsInspected)
    {
      setOpenInspectionFinishedDialog(true);
    }
    else
    {
      navigate("/");
    }
  }

  const handleInspectionFinished = () =>
  {
    updateDoc(doc(db, `/operators/gheS3SffzfnjTP3al04p/orders/${ location.state.order.id }`), {
      status: "finished",
      finish_time: new Date().toLocaleString()
    });

    navigate("/");
  }

  const handleInspectionFinishedDialogCancel = () =>
  {
    setOpenInspectionFinishedDialog(false);
  }
 
  return (
    <>
    <Box sx={{
        display: 'flex',
        p: 1,
        m: 1,
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        }}>
      <OrderHeader orderInfo={location.state.order}/>
      <IconButton sx={{padding: 0}} aria-label="close" color="primary" onClick={() => {handleOnCloseInspection()}}>
        <CloseIcon />
      </IconButton>
      </Box>

      <EquipmentList orderInfo={location.state} onAllItemsInspected={() => handleAllItemsInspected()} />

      {openInspectionFinishedDialog && <InspectionFinishedDialog onInspectionFinished={() => handleInspectionFinished()} onCancel={() => handleInspectionFinishedDialogCancel()}/>}
    </>
  );
}