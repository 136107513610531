import React from "react";
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import { ActiveOrders } from "../orders/ActiveOrders";
import { ActiveDefects } from "../defect/ActiveDefects";
import { OrderCreationDialog} from "../orders/OrderCreationDialog";

export function Dashboard()
{
  const [openOrderCreationDialog, setOrderCreationDialog] = React.useState(false);
  const navigate = useNavigate();
  
  const handleNewOrder = () =>
  {
    setOrderCreationDialog(true);
  };

  const handleOrderCreationDialogClose = (order) =>
  {
    console.log("Created Order:", order);

    setOrderCreationDialog(false);
    
    navigate("/order/", { state: { order: order } });
  };

  return (
    <>
    <OrderCreationDialog
        open={openOrderCreationDialog}
        onClose={handleOrderCreationDialogClose}
    />
    <ActiveDefects/>
    <ActiveOrders/>
    <Box sx={{ '& > :not(style)': { m: 1 }, display: 'flex', justifyContent: 'flex-end'}}>
      <Fab color="primary" aria-label="add" size="medium" onClick={() => {handleNewOrder()}}>
        <AddIcon />
      </Fab>
    </Box>
    </>
  );
};