import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function OrderHeader(props)
{
  return (
    <>
    <Box>
      <Typography variant="h6" component="div">
        {props.orderInfo.playground}
      </Typography>
      <Typography variant="button" display="block">
        {props.orderInfo.type}
      </Typography>
      <Typography variant="button" display="block">
        {props.orderInfo.creation_time}
      </Typography>
    </Box>
    </>
  );
}

export default OrderHeader