import React from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function InspectionFinishedDialog(props)
{
  const [open, setOpen] = React.useState(true);

  const handleCancel = () =>
  {
    setOpen(false);

    props.onCancel();
  };

  const handleFinished = () =>
  {
    setOpen(false);

    props.onInspectionFinished();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Inspektion abschließen</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Sie haben alle Geräte geprüft.
          Wollen Sie die Inspektion jetzt beenden?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleFinished}>Ja</Button>
        <Button onClick={handleCancel}>Nein</Button>
      </DialogActions>
    </Dialog>
  );
}