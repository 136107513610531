import React, { useState, } from "react";

import
{
  useNavigate,
} from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

export function ActiveDefects()
{
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  function orderClicked(order)
  {
    navigate("/order/", {
      state: { order: order }
    });
  }

  return (
    <>
    <h4>Noch nicht behobene Mängel</h4>
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {orders &&
        orders.map((order) => {
          const { playground, id, avatarUrl, type } = order;
          console.log("Order", order);
          return (
            <>
            <Divider variant="inset" component="li" />
            <ListItem key={id} alignItems="flex-start" onClick={() => orderClicked(order)}>
              <ListItemAvatar>
                <Avatar alt={id} src={avatarUrl} />
              </ListItemAvatar>
              <ListItemText
                primary={playground}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {type}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            </>
          );
        })}
      <Divider variant="inset" component="li" />
    </List>
    </>
  );
}
