import React, { useEffect, useState, } from "react";

import
{
  useNavigate,
} from "react-router-dom";

import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import OrderDTO, {orderConverter} from "../orders/OrderDTO";

export function ActiveOrders()
{
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() =>
  {
    getOrders();
  }, []);

  const getOrders = async () =>
  {
    
    const ordersRef = collection(db, "/operators/gheS3SffzfnjTP3al04p/orders");

    // Create a query against the collection.
    // TODO user hardcoded
    const q = query(
      ordersRef,
      where("status", "==", "active"),
      where("user", "==", "SK43wCHZnvaqQSIKGrPZugTAq2A3")
    ).withConverter(orderConverter);

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) =>
    {
      const order = new OrderDTO(doc.data());
      order.id = doc.id;

      setOrders(orders => orders.concat(order));
    });
  };

  function orderClicked(order)
  {
    navigate("/order/", {
      state: { order: order }
    });
  }

  return (
    <>
    <h4>Offene Aufträge</h4>
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {orders &&
        orders.map((order) => {
          const { playground, id, avatarUrl, type } = order;
          console.log("Order", order);
          return (
            <>
            <Divider variant="inset" component="li" />
            <ListItem key={id} alignItems="flex-start" onClick={() => orderClicked(order)}>
              <ListItemAvatar>
                <Avatar alt={id} src={avatarUrl} />
              </ListItemAvatar>
              <ListItemText
                primary={playground}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {type}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            </>
          );
        })}
      <Divider variant="inset" component="li" />
    </List>
    </>
  );
}
