class OrderDTO
{
  id;
  type;
  status;
  user;
  playground;
  avatarUrl;
  avatar;
  equipment;
  creation_time;
  number_of_defects;

  constructor(data)
  {
    this.id     = data.id;
    this.type   = data.type  || "Visuelle Routine-Inspektion";
    this.status = data.status || "active";
    this.user   = data.user;
    this.playground = data.playground ? data.playground : "";
    this.playground_id = data.playground_id;
    this.avatarUrl = data.avatarUrl;
    this.avatar = data.avatar;
    this.equipment = [];
    this.creation_time = data.creation_time || new Date().toLocaleString();
    this.number_of_defects = data.number_of_defects || 0;
  }

  addEquipment(eq)
  {
    this.equipment.push(eq);
  }
}

// Firestore data converter
export const orderConverter = {
  toFirestore: (order) => {
      return {
          playground: order.playground,
          playground_id: order.playground_id,
          status: order.status,
          type: order.type,
          user: order.user,
          avatar: order.avatar,
          creation_time: order.creation_time,
          number_of_defects: order.number_of_defects
          };
  },
  fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      const order = new OrderDTO(data);

      return order;
  }
};

export default OrderDTO;