import React, { Component } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ButtonAppBar } from "./components/ApplicationBar";
import { Login } from "./components/login/Login";
import { Register } from "./components//register/Register";
import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import { Dashboard } from "./components/dashboard/Dashboard";
import { Order } from "./components/orders/Order";
import UserProfileContextProvider from './contexts/UserProfileContext'

import "./App.css";

const RouteRequiresLogin = ({ children }) => {
  const [user, loading ] = useAuthState(auth);

  if (!loading) {
    console.log("LoggedIn user:", user);
    return user ? children : <Login />;
  }

  return null;
};

class App extends Component {
  render() {
    return (
      <>
        <BrowserRouter>
        <UserProfileContextProvider>
          <div>
            <Routes>
              <Route exact path="/"
                element=
                {
                  <RouteRequiresLogin>
                    <ButtonAppBar />
                    <Dashboard />
                  </RouteRequiresLogin>
                }
              />
              <Route path="/register"
                element=
                {
                  <Register />
                }
              />

              <Route exact path="/order"
                element=
                {
                  <RouteRequiresLogin>
                    <ButtonAppBar />
                    <Order />
                  </RouteRequiresLogin>
                }
              />
              
              <Route path="*"
                element=
                {
                  <Navigate to="/" />
                }
              />
            </Routes>
          </div>
          </UserProfileContextProvider>
        </BrowserRouter>
      </>
    );
  }
}

export default App;
