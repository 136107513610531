import React, {useState, useRef } from "react";

import Box from "@material-ui/core/Box";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import TextField from '@mui/material/TextField';

function DefectDialog(props)
{
  const [images, setImages]   = useState([]);
  const [reason, setReason]   = useState('');

  const handleFileInput = useRef(null);
  const stripRef        = useRef(null);

  const handleCancel = () =>
  {
    props.onCancel();
  };

  const handleOk = () =>
  {
    let defectProps = 
    {
      reason: reason,
      images: images 
    };

    props.onDefectReasonEntered(defectProps);
  };

  const handleReasonChange = event =>
  {
    setReason(event.target.value);
  };

  const handleImageChange = (event) =>
  {
    let strip = stripRef.current;
    const data = URL.createObjectURL(event.target.files[0]);

    const link = document.createElement("a");
    link.href = data;
    link.setAttribute("download", "myWebcam");
    link.innerHTML = `<img src='${data}' alt='thumbnail' width="100" height="100"/>`;
    strip.insertBefore(link, strip.firstChild);

    setImages(images => [...images, event.target.files[0]]);
  }

  const takePhoto = () =>
  {
    handleFileInput.current.click();
  };

  return (
    <>
    <Dialog open={true}>
      <DialogTitle>Mangel erfassen</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          multiline
          fullWidth 
          placeholder="Bitte den Mangel beschreiben."
          rows={4}
          margin="dense"
          id="name"
          label="Mangeltext"
          type="text"
          onChange={handleReasonChange}
          value={reason}
        />
        <div>
          <Box m={1} display="flex" justifyContent="center" alignItems="center">
            <IconButton onClick={() => takePhoto()} color="primary" aria-label="upload picture" component="label">
              <PhotoCamera />
            </IconButton>
          </Box>
          <label>
            <input style={{ display: "none" }} ref={handleFileInput} id="myFileInput" type="file" accept="image/*" capture="user" onChange={handleImageChange}></input>
          </label>
          <div ref={stripRef} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Abbrechen</Button>
        <Button onClick={handleOk} disabled = {reason === ''} >Fertig</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DefectDialog